<template>
  <div class="page page__news-item">
    <esmp-loader v-show="loading" fix />

    <article-item
      v-if="newsItem"
      :title="newsItem.title"
      :content="newsItem.content"
      :main-image-url="newsItem.mainImageUrl"
      :create-date="newsItem.startDatetime"
      :attachments="newsItem.attachments"
    />
  </div>
</template>

<script>
import ArticleItem from '@/components/article-item';
import getFileExtension from '@/helpers/getFileExtension';
import getFileFormat from '@/helpers/getFileFormat';

export default {
  name: 'NewsItem',

  components: { ArticleItem },

  data() {
    return {
      newsItem: null,
      loading: false,
    };
  },

  computed: {
    currentNewsItemId() {
      return this.$route.params.id;
    },
  },

  created() {
    this.getNewsItem();
  },

  methods: {
    async getNewsItem() {
      this.loading = true;

      const { data: newsItem } = await this.$API.news.getNewsItem(this.currentNewsItemId);

      newsItem.attachments = newsItem.files.map((file) => ({
        ...file,
        name: file.originalFilename,
        format: getFileFormat(file.originalFilename),
        extension: getFileExtension(file.originalFilename),
      }));

      this.newsItem = newsItem;

      this.loading = false;

      this.$router.setMetaOption(this.$route, 'title', newsItem.title);
    },
  },
};
</script>
