import getFileExtension from './getFileExtension';

const getFileFormat = (fileName) => {
  const ext = getFileExtension(fileName);
  let type = 'document';

  if (['gif', 'jpg', 'jpeg', 'png', 'bmp', 'webp'].includes(ext)) {
    type = 'image';
  } else if (['mp4', 'm3u8', 'rmvb', 'avi', 'swf', '3gp', 'mkv', 'flv'].includes(ext)) {
    type = 'video';
  } else if (['mp3', 'wav', 'wma', 'ogg', 'aac', 'flac'].includes(ext)) {
    type = 'music';
  } else if (['pdf'].includes(ext)) {
    type = 'pdf';
  } else if (['rar', 'zip', 'iso', 'tar', '7z', 'gz'].includes(ext)) {
    type = 'zip';
  } else if (['numbers', 'csv', 'xls', 'xlsx'].includes(ext)) {
    type = 'excel';
  } else if (['keynote', 'ppt', 'pptx'].includes(ext)) {
    type = 'presentation';
  }
  return type;
};

export default getFileFormat;
